@-webkit-keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .ant-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 2000;
    width: 100%;
    pointer-events: none;
    .anticon {
      position: relative;
      top: 1px;
      margin-right: 8px;
      font-size: 16px;
    }
  }
  .ant-message-notice {
    padding: 8px;
    text-align: center;
  }
  .ant-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
  }
  .ant-message-success {
    .anticon {
      color: #52c41a;
    }
  }
  .ant-message-error {
    .anticon {
      color: #ff4d4f;
    }
  }
  .ant-message-warning {
    .anticon {
      color: #faad14;
    }
  }
  .ant-message-info {
    .anticon {
      color: #1890ff;
    }
  }
  .ant-message-loading {
    .anticon {
      color: #1890ff;
    }
  }
  .ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
    -webkit-animation-name: MessageMoveOut ;
    animation-name: MessageMoveOut ;
    -webkit-animation-duration: .3s ;
    animation-duration: .3s ;
  }
  .ant-message-rtl {
    direction: rtl;
    span {
      direction: rtl;
    }
    .anticon {
      margin-right: 0;
      margin-left: 8px;
    }
  }